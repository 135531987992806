exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-check-license-js": () => import("./../../../src/pages/check-license.js" /* webpackChunkName: "component---src-pages-check-license-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-loan-calculator-js": () => import("./../../../src/pages/loan-calculator.js" /* webpackChunkName: "component---src-pages-loan-calculator-js" */),
  "component---src-pages-loan-calculator-v-2-js": () => import("./../../../src/pages/loan-calculator-v2.js" /* webpackChunkName: "component---src-pages-loan-calculator-v-2-js" */),
  "component---src-pages-mothercredit-js": () => import("./../../../src/pages/mothercredit.js" /* webpackChunkName: "component---src-pages-mothercredit-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-other-faqs-js": () => import("./../../../src/pages/other-faqs.js" /* webpackChunkName: "component---src-pages-other-faqs-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-article-detail-js": () => import("./../../../src/templates/ArticleDetail.js" /* webpackChunkName: "component---src-templates-article-detail-js" */)
}

